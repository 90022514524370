import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'rate',
    'rateContainer'
  ]

  connect() {
    $(this.element).on('dataChange', this.updateTargets.bind(this))
  }

  updateTargets(_event, data) {
    if (data['status'] === 'available') {
      this.updateRate(data['rate']);
    } else {
      let reason = data['reason'] || 'No rooms available'
      this.showUnavailabilityNotice(reason);
    }
  }

  updateRate(rate) {
    $(this.rateTarget).html(rate)
  }

  showUnavailabilityNotice(reason) {
    $(this.rateContainerTarget).html(`
      <span class='fw-medium text-danger'>
        ${reason.replace("\n", '</br>')}
      </span>
    `)
  }
}
