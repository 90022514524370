import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'totalPrice',
    'nextStepButton',
    'submitButton',
    'extrasSection',
    'extrasPrice',
    'payButton'
  ]

  connect() {
    this.reservationPrice = parseFloat(this.data.get('reservation-price'));
    this.currency = this.data.get('currency');

    $(this.element).on(`recalculatePrice`, this.updatePrice.bind(this))
    document.addEventListener('scroll', this.controlSumbitButtonVisibility.bind(this));
  }

  updatePrice(_e, data) { 
    this.wifiPrice = data.wifiPrice
    this.wifiBatteryPrice = data.wifiBatteryPrice

    this.updateExtrasSection();
    this.updateTotalPrice();
  }

  updateExtrasSection() {
    console.log(this.extrasPriceTarget)

    if (this.wifiPrice.price > 0) {
      this.extrasSectionTarget.classList.remove('d-none');
      this.extrasPriceTarget.innerHTML = `${this.extrasPrice} ${this.currency}`
    } else {
      this.extrasSectionTarget.classList.add('d-none');
    }
  }

  updateTotalPrice() {
    this.totalPriceTarget.innerHTML = `${this.totalPrice} ${this.currency}`
  }

  updateButton() {
    this.nextStepButtonTarget.classList.add('collapse');
    this.submitButtonTarget.classList.remove('collapse');
    this.submitButtonTarget.value = `${this.submitButtonTarget.value} ${this.totalPriceTarget.innerText}`;
  }

  controlSumbitButtonVisibility() {
    if(!document.getElementById('dropin')) { return }
    
    const paymentElementY = document.getElementById('dropin').getBoundingClientRect().top
    if (paymentElementY < window.scrollY) {
      this.payButtonTarget.classList.add('visually-hidden');
    } else {
      this.payButtonTarget.classList.remove('visually-hidden');
    }
  }

  get totalPrice() {
    let reservationPrice = this.reservationPrice,
        wifiPrice        = this.wifiPrice.price,
        wifiBatteryPrice = this.wifiBatteryPrice.price ;

    return (reservationPrice + wifiPrice + wifiBatteryPrice).toFixed(2);
  }

  get extrasPrice() {
    let wifiPrice        = this.wifiPrice.price,
        wifiBatteryPrice = this.wifiBatteryPrice.price;

    return (wifiPrice + wifiBatteryPrice).toFixed(2);
  }
}
