import { Controller } from "@hotwired/stimulus";
import axios  from 'axios'
import { Modal, Collapse } from 'bootstrap.native'
import feather from 'feather-icons'
import changeInputCount from '../modules/changeInputCount'
import readmoreConfig from '../config/readmoreConfig'

export default class extends Controller {
  static targets = [
    'rooms',
    'reviews',
    'reviewsSort',
    'spinner',
    'newBookingSessionForm',
    'totalRoomsCount',
    'totalAmount',
    'room',
    'roomCounter',
    'decrementRoomCountBtn',
    'incrementRoomCountBtn',
    'cancellationPolicyRates',
    'priceSection',
    'headStartingAtPrice',
    'planCost'
  ]

  initialize() {
    this.hotel_slug = this.data.get('slug');
    if(this.hasReviewsTarget) {
      this.reviewSpinner = $(this.reviewsTarget).siblings('.material-symbols-outlined');
    }
    this.roomsCount = parseInt(this.data.get('rooms-count') || 1);
  }

  connect() {
    this.fetchRooms();
    this.fetchReviews();
    readmoreConfig({ collapsedHeight: 50, linkText: 'Show full details', className: '.hotel-details--facilities-list' });
    this.setSmoothScroll();
  }

  fetchRooms() {
    axios.get(`/hotels/${this.hotel_slug}/rooms${location.search}`)
      .then(res => this.appendRooms(res.data))
      .finally(() => {
        this.updateHeadStartingAtPrice();
        this.spinnerTarget.style.display = 'none';
      })
  }

  appendRooms(html_fragment) {
    this.roomsTarget.insertAdjacentHTML('beforeend', html_fragment)
    feather.replace();
  }

  fetchReviews(event) {
    if (this.hasReviewsTarget) {
      $(this.reviewsTarget).hide();
      $(this.reviewsTarget).html('');
      this.reviewSpinner.show();
      axios.get(this.reviewsTarget.dataset.reviewsUrl).then(res => {
        this.displayReviews(res.data);
        $(this.reviewsTarget).show();
      })
    }
  }

  appendReviews(event) {
    if (this.hasReviewsTarget) {
      event.preventDefault();
      $(event.target).fadeTo('slow', 0);

      $(event.target).remove();
      this.reviewSpinner.show();

      axios.get(event.target.href).then(res => {
        this.displayReviews(res.data);
        $(this.reviewsTarget).show();
      });
    }
  }

  displayReviews(html) {
    if (this.hasReviewsTarget) {
      var reviews = $(html).hide();
      $(this.reviewsTarget).append(reviews);
      reviews.show();
      feather.replace();
      this.reviewSpinner.hide();
    }
  }

  updateTotalAfterCheck(event) {
    let totalAmount = parseFloat(event.target.dataset.roomCost);

    $(this.totalRoomsCountTarget).text(this.roomsCount);
    $(this.totalAmountTarget).text(totalAmount.toFixed(2));
  }

  // For select-one booking forms
  startBookingSession(event) {
    var form = $(this.newBookingSessionFormTarget);
    const optionSelected = this.optionSelected(event);

    event.preventDefault();

    if (optionSelected) {
      optionSelected.checked = true;
      form.trigger('submit');
    } else {
      toastr.error(`Select room`)
    }
  }

  updateCancellationPolicy(event) {
    let template = $("input[name='booking_session[rate_plan]']:checked").closest('.room__row').find('template').html()

    $(this.cancellationPolicyRatesTarget).html(template);
  }

  fetchMinPrice() {
    const pricesArr = Array.from(this.planCostTargets).map((x) => parseFloat(x.innerText)).filter(Number);

    if (pricesArr.length < 1) { return false }

    return Math.min(...pricesArr);
  }

  updateHeadStartingAtPrice() {
    const startingAtPrice = this.fetchMinPrice();

    if(startingAtPrice) { 
      this.headStartingAtPriceTarget.innerText = startingAtPrice 
    } else {
      this.priceSectionTarget.style.display = 'none';
    }
  }

  setSmoothScroll() {
    $("#sections-nav a").on('click', function(event) {
      if (this.hash !== "") {
        event.preventDefault();
        var hash = this.hash;
        $('html, body').animate({
          scrollTop: $(hash).offset().top - 56
        }, 800, function() {
        });
      }
    });
  }

  optionSelected(e) {
    const inputField = e.target.form.querySelector(`#${e.target.getAttribute('for')}`);
    return inputField;
  }

  // Multi-room booking

  // startBookingSession(event) {
  //   var form = $(this.newBookingSessionFormTarget);

  //   event.preventDefault();

  //   if (this.totalRoomsCount() === this.roomsCount) {
  //     form.trigger('submit');
  //   } else {
  //     let missingRoomsCount = this.roomsCount - this.totalRoomsCount();
  //     let suffix = missingRoomsCount == 1 ? '' : 's';
  //     toastr.error(`Select ${missingRoomsCount} more room${suffix}`)
  //   }
  // }

  // updateTotal(event) {
  //   let self = this;

  //   if (this.totalRoomsCount() > this.roomsCount) {
  //     let value = parseInt($(event.target).siblings('input').val() || 0);
  //     $(event.target).siblings('input').val(value - 1);
  //   }

  //   $(this.totalRoomsCountTarget).text(self.totalRoomsCount());
  //   $(this.totalAmountTarget).text(self.totalAmount().toFixed(2));
  // }

  // totalAmount() {
  //   let a = this.roomTargets.map(x => parseFloat(x.dataset.roomCost));
  //   let b = this.roomCounterTargets.map(x => parseInt($(x).val() || 0));

  //   return a.map((x, i) => b[i] * x).reduce((x, y) => x + y);
  // }

  // totalRoomsCount() {
  //   return this.roomCounterTargets.map(x => parseInt($(x).val() || 0)).reduce((x, y) => x + y);
  // }

  // changeInputCount(event) {
  //   changeInputCount(event);
  // }
}
