import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = []

  connect() {
    this.reservationPrice = parseFloat(this.data.get('reservation-price'));
    this.currency = this.data.get('currency');

    $(this.element).on(`recalculatePrice`, this.updatePrice.bind(this))
  }

  updatePrice(_e, data) {
    console.log('sidebar')

    this.wifiPrice = data.wifiPrice
    this.wifiBatteryPrice = data.wifiBatteryPrice

    console.log(this)
    this.updateWifiSection();
    this.updateWifiPriceContainer();
    this.updateBatteryPriceContainer();
    this.updateTotalPrice();
  }

  updateWifiSection() {
    let $template = $('.reservation--wifi_order-details');
    
    if (this.wifiPrice.price > 0) {
      $template.show();
    } else {
      $template.hide();
    }
  }

  updateWifiPriceContainer() {
    let $wifiPriceContainer = $('.reservation--wifi_order-details__price');

    if (this.wifiPrice.price > 0) {
      $wifiPriceContainer.find('span.price').html(this.wifiPrice.priceFormatted)
    } else {
      $wifiPriceContainer.find('span.price').html('')
    }
  }
  
  updateBatteryPriceContainer() {
    let $batteryPriceContainer = $('.reservation--wifi_order-details__price_battery');

    if (this.wifiBatteryPrice.price > 0) {
      $batteryPriceContainer.show();

      $batteryPriceContainer.find('span.price')
                            .html(this.wifiBatteryPrice.priceFormatted);
    } else {
      $batteryPriceContainer.hide();
      $batteryPriceContainer.find('span.price').html('');
    }
  }

  updateTotalPrice() {
    let $priceContainer = $('.reservation--total-price');

    $priceContainer.html(`${this.totalPrice} ${this.currency}`)
  }

  get totalPrice() {
    let reservationPrice = this.reservationPrice,
        wifiPrice        = this.wifiPrice.price,
        wifiBatteryPrice = this.wifiBatteryPrice.price ;

    return (reservationPrice + wifiPrice + wifiBatteryPrice).toFixed(2);
  }
}
