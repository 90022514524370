import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr"
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin'

export default class extends Controller {
  static targets = [
    'dateFrom',
    'dateTo'
  ]

  initialize() {
    // if When search dates are incomplete or not set,
    // then set default range for 7 days from tomorrow 
    this.defaultSearchFor_x_days = 7;
  }
  connect() {
    this.fromValue = this.dateFromTarget.value;
    this.toValue = this.dateToTarget.value;
    this.initDatepicker();
  }

  initDatepicker() {  
    let flatpickrOptions = {
        disableMobile: 'true',
        minDate: new Date().fp_incr(1),
        allowInput: false,
        altInput: true,
        altFormat: "d M, Y",
        "plugins": [new rangePlugin({ input: this.dateToTarget })],
    }

    flatpickr(this.dateFromTarget, flatpickrOptions);
  }
}
