import { Controller } from "@hotwired/stimulus";
import axios  from 'axios'
import feather  from 'feather-icons'
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [
    "form",
    "list",
    "minRangeInput",
    "maxRangeInput",
    "rangeInputBar",
    "minPriceInput",
    "maxPriceInput",
    "ratingButtons",
    "filterCheckbox",
    "clearFiltersBtn",
    "results",
    "spinner"
  ]

  initialize() {
    this.room_id = this.data.get('room')
    this.updateSearchParams();
    this.fetchHotels = _.debounce(this.fetchHotels, 1000, { 'leading': false })
  }

  connect() {
    this.toggleClearFiltersBtn();
    this.fetchHotelsDetails();
    this.updatePriceRangeBar();
  }

  disconnect() {
    this.channel.unsubscribe();
  }

  toggleClearFiltersBtn() {
    if($(this.filterCheckboxTargets).is(':checked')) {
      $(this.clearFiltersBtnTarget).show();
    } else {
      $(this.clearFiltersBtnTarget).hide();
    }
  }

  updatePrice(e) {
    const targetName = e.target.dataset.hotelSearchTarget.split('.').at(-1);
    const priceGap = 1000;
    let minPrice = parseInt(this.minPriceInputTarget.value);
    let maxPrice = parseInt(this.maxPriceInputTarget.value);

    if (targetName && targetName.includes('min')) {
      minPrice = parseInt(this[`${targetName}Target`].value);
      if (maxPrice - minPrice < 0) { minPrice = maxPrice - priceGap };
      this.minPriceInputTarget.value = minPrice;
    } else if (targetName && targetName.includes('max')) {
      maxPrice = parseInt(this[`${targetName}Target`].value);
      if (maxPrice - minPrice < 0) { maxPrice = minPrice + priceGap }
      this.maxPriceInputTarget.value = maxPrice;
    }

    this.updatePriceRangeBar();
  }

  updatePriceRangeBar() {
    let minPrice = parseInt(this.minPriceInputTarget.value);
    let maxPrice = parseInt(this.maxPriceInputTarget.value);

    this.minRangeInputTarget.value = minPrice;
    this.maxRangeInputTarget.value = maxPrice;
    const validMinPrice = this.inRange(minPrice, this.minRangeInputTarget.min, this.minRangeInputTarget.max)
    const validMaxPrice = this.inRange(maxPrice, this.maxRangeInputTarget.min, this.maxRangeInputTarget.max)
    if(validMinPrice) { this.rangeInputBarTarget.style.left = (minPrice / this.minRangeInputTarget.max) * 100 + "%"; }
    if(validMaxPrice) { this.rangeInputBarTarget.style.right = 100 - (maxPrice / this.maxRangeInputTarget.max) * 100 + "%"; }
  }

  updateRating(e) {
    const selectedRating = e.target.value;

    this.ratingButtonsTargets.forEach(label => {
      const buttonRating = label.querySelector('input[type="radio"]').value;
      label.classList.toggle("active", buttonRating === selectedRating);
    });
  }

  clearFilters(e) {
    e.preventDefault();
    $(this.filterCheckboxTargets).prop('checked', false);
    $(this.clearFiltersBtnTarget).hide();
    this.fetchHotels();
  }

  fetchHotels() {
    $(this.listTarget).addClass('opacity-20');
    $(this.spinnerTarget).show();

    let queryString = `?${$(this.formTarget).serialize()}`
    var url = [location.protocol, '//', location.host, location.pathname].join('');

    axios.get(
      `/hotels${queryString}`,
      {headers: {'X-Requested-With': 'XMLHttpRequest'}}
    ).then(res => {
      $(this.resultsTarget).html(res.data);
      feather.replace();
      this.toggleClearFiltersBtn();
      window.history.replaceState(null, '', url + queryString);
      this.updateSearchParams();
      this.channel.perform('get_hotels', this.searchParams);
      $(this.spinnerTarget).hide();
      $(this.listTarget).removeClass('opacity-20');
    })
  }

  updateSearchParams() {
    this.searchParams = JSON.parse(this.listTarget.dataset.hotelSearchParams);
  }

  fetchHotelsDetails() {
    var self = this;

    return new Promise((resolve) => {
      self.channel = consumer.subscriptions.create(
        {
          channel: 'HotelSearchChannel',
          room_id: self.room_id
        } ,
        {
          connected() {
            if (self.isSearchPeriodAvailable()) {
              this.perform('get_hotels', self.searchParams);
            }
            resolve();
          },
          received(data) {
            if (data['action'] === 'updateRates') {
              let payload = data.payload.rates

              for (var hotelSlug in payload) {
                self.updateHotelDetails(hotelSlug, payload[hotelSlug])
              }
            };
          }
        });
    });
  };

  updateHotelDetails(slug, data) {
    $(this.listTarget)
      .find(`*[data-slug=${slug}]`)
      .trigger('dataChange', data)
  };

  isSearchPeriodAvailable() {
    return  this.searchParams &&
            this.searchParams['arrival_date'] != null &&
            this.searchParams['departure_date'] != null;
  }

  inRange(x, min, max) {
    return ((x-min)*(x-max) <= 0);
  }
}
