import { Controller } from "@hotwired/stimulus";
import Choices from 'choices.js'
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin'
import feather from "feather-icons"
import changeInputCount from '../modules/changeInputCount'

export default class extends Controller {
  static targets = [
    'cityInput',
    'cityInputContainer',
    'roomsCount',
    'guestsField',
    'guestsAdultsInput',
    'guestsChildrenInput'
  ]

  initialize() {
    if (this.data.get('recommendations') !== null && this.data.get('recommendations') !== 'null') {
      this.recommendations = JSON.parse(this.data.get('recommendations'));
      this.defaultChoices = this.recommendations.map(cityData => this.mapCityToChoiceOption(cityData, 'disable'));
    } else {
      this.recommendations = [];
      this.defaultChoices = [];
    }

    if (this.data.get('location') !== null) {
      let locationData = JSON.parse(this.data.get('location'));
      let selectedPlace = this.mapCityToChoiceOption(locationData, locationData.slug);

      this.defaultChoices = [...this.defaultChoices, selectedPlace];
    }
  }

  connect() {
    this.bindChoicesToCitiesInput();
    this.updateGuestsField();
  }

  updateGuestsField() {
    var adultsCount = parseInt(this.guestsAdultsInputTarget.value) || 0;
    var childrenCount = parseInt(this.guestsChildrenInputTarget.value) || 0;
    var adultsWord = adultsCount === 1 ? 'adult' : 'adults';
    var childrenWord = childrenCount === 1 ? 'child' : 'children';
    var text = [adultsCount, adultsWord, childrenCount, childrenWord].join(' ')

    if(adultsCount >= 1 && adultsCount <= 9 && childrenCount >= 0 && childrenCount <= 9) {
      $(this.guestsFieldTarget).html(text);
    }
  }

  bindChoicesToCitiesInput() {
    let self = this;

    const element = new Choices(this.cityInputTarget, {
      choices: self.defaultChoices,
      searchResultLimit: 25,
      renderChoiceLimit: 25,
      searchPlaceholderValue: 'Type or pick a location',
      noResultsText: 'No results found. <br/>Check our recommeded locations:',
      removeItemButton: true,
      itemSelectText: '',
      shouldSort: false,
      searchFloor: 3,
      classNames: {
        containerInner: 'choices__inner form-control',
      },
      callbackOnCreateTemplates: function(template) {
        return {
          choice: (classNames, data) => {
            return template(`
            <div class="${classNames.item} ${classNames.itemChoice} ${
              data.disabled ? classNames.itemDisabled : classNames.itemSelectable
            }" data-select-text="${this.config.itemSelectText}" data-choice ${
              data.disabled
                ? 'data-choice-disabled aria-disabled="true"'
                : 'data-choice-selectable'
            } data-id="${data.id}" data-value="${data.value}" ${
              data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
            }>
              <i class='mb-1 mx-2' data-feather='map-pin'
                height='15' width='15'></i> ${data.label}
              <span class='ms-2 small text-gray'>
                ${data.customProperties.prefecture}
                </span>
            </div>
          `);
          },
        };
      },
      callbackOnInit: function() {
        if (self.recommendations.length === 0) { return }
        let recommended = self.recommendations.slice(0,6);
        let div = `
          <div id="hotel-searchbar--recommended-areas"
          class="hotel-searchbar--recommended-areas">
          <div class="container py-2">
          <div class="row">
        `;
        recommended.forEach(area => div += `
          <div class='col-6'>
            <div class='d-flex hotel-searchbar--recommended-areas-tile'
                data-area-slug='${area.slug}'>
              <div class='me-2 hotel-searchbar--recommended-areas-photo'>
                <img src="${area.photo}">
              </div>
              <div class='my-auto'>
                <div class='font-weight-600'>${area.name}</div>
                <div class='small text-primary'>
                  ${area.hotels_count} objects
                </div>
              </div>
            </div>
          </div>
        `);
        div += `
            </div>
            </div>
              <div class='bg-white p-2 text-end font-weight-600'>
                <a class='hotel-searchbar--recommended-areas-all-link' href='#'>
                  All recommendations
                  <i class='ms-1 mb-1 text-primary'
                  data-feather='star' height='14' width='14'></i>
                </a>
              </div>
            </div>
          `;
        let el = this.choiceList.element;
        el.insertAdjacentHTML("afterend", div);
      }
    });

    $('.hotel-searchbar--recommended-areas-tile').on('click', function() {
      element.setChoices(self.defaultChoices, 'value', 'label', true);
      element.setChoiceByValue(this.dataset.areaSlug);
      element.hideDropdown();
    });

    element.passedElement.element.addEventListener('showDropdown', function() {
      $(element.choiceList.element).hide();
      $('#hotel-searchbar--recommended-areas').show();
      feather.replace();
    });

    element.passedElement.element.addEventListener('hideDropdown', function() {
      $(element.input.element).val('');
    });

    element.passedElement.element.addEventListener('search', _.debounce(async function(event) {
      $(element.choiceList.element).show();
      $('#hotel-searchbar--recommended-areas').hide();

      const data = await fetch(`/api/location/autocomplete?q=${encodeURIComponent(event.detail.value)}`);
      const results = await data.json();
      const choices = results.map(cityData => self.mapCityToChoiceOption(cityData, 'selectedCityID'));
      element.setChoices(choices, 'value', 'label', true);

      if(event.detail.resultCount === 0 || choices.length === 0) {
        $('#hotel-searchbar--recommended-areas').show();
      }
      feather.replace();
    }, 400));

    element.passedElement.element.addEventListener('removeItem', function() {
      feather.replace();
    });

    element.input.element.addEventListener('input', function() {
      if($(this).val().length < 3) {
        $(element.choiceList.element).hide();
        $('#hotel-searchbar--recommended-areas').show();
      }
    });
  }

  changeInputCount(event) {
    changeInputCount(event)
  }

  disconnect() {
    // Without this choices will be initialized second time on turbloinks:load
    this.resetChoicesElements()
  }

  resetChoicesElements() {
    $(this.cityInputTarget).appendTo($(this.cityInputContainerTarget))
      .removeClass('choices__input', 'is-hidden')
      .removeAttr('data-choice');
    $(this.element).find('.choices').remove();
  }

  mapCityToChoiceOption(cityData, selectedCityID) {
    return {
      value: cityData.slug,
      label: cityData.name,
      customProperties: {
        hotels_count: cityData.hotels_count,
        prefecture: cityData.prefecture,
        photo: cityData.photo
      },
      selected: (slug => slug === selectedCityID)(cityData.slug)
    }
  }
}
