import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    'form',
    'wifiEnabledCheckBox',
    'wifiBatteryEnabledCheckBox',
		'wifiAirportDeliveryCheckBox',
		'wifiHotelDeliveryCheckBox',
    'periodSelect'
  ]

  connect() {
    this.defaultNumberOfDays = parseInt(this.data.get('days'))
    let pricesWifi = [];
    
    _.each(JSON.parse(this.data.get('prices')), function(x){ 
      pricesWifi[x.days] = parseFloat(x.price)
    });

    // console.log(this.defaultNumberOfDays)
    console.log(pricesWifi)

    this.pricingContext = {
      pricesWifi: pricesWifi,
      priceBattery: parseFloat(this.data.get('battery-price')),
      currency: 'USD'
    }

    this.toggleAirportFields();
    this.updateSummaryBars();
  }

  changleDeliveryMethod(event) {
    event.preventDefault();
		var deliveryMethod = $(event.currentTarget).data('deliveryMethod');

    console.log('click');
    console.log(deliveryMethod)

    this.updateWifiDeliveryCheckBoxes(deliveryMethod);
    this.toggleAirportFields();

    $('html, body').animate({
      scrollTop: $(`.pickup_option--${deliveryMethod}`).offset().top
    }, 100);
  }

  changeRentalPeriod(event) {
    var currentValue = parseInt(this.periodSelectTarget.value),
        modifier = parseInt(event.currentTarget.dataset.value),
        nextValue = currentValue + modifier,
        maxValue = Array.from(this.periodSelectTarget.children).reduce((opt, res) => { return opt.value > res ? opt.value : res }, 1).value;

    if (nextValue >= 1 && nextValue <= maxValue) {
      this.periodSelectTarget.value = nextValue;
    }
    this.updateSummaryBars();
  }

  toggleForm(event) {
    if (event.currentTarget.checked) {
      $(this.formTarget).removeClass('reservation__pocket_wifi_subform--diabled')
      $(this.formTarget).find('input, select').prop("disabled", false);
      this.toggleAirportFields();
    } else {
      $(this.formTarget).addClass('reservation__pocket_wifi_subform--diabled')
      $(this.formTarget).find('input, select').prop("disabled", true);
    }
    this.updateSummaryBars();
  }

  toggleAirportFields() {
    if (this.deliveryMethod == 'airport') {
      $('.airport-only').show()
      $('.airport-only').find('input, select').prop("disabled", false);
    } else {
      $('.airport-only').hide()
      $('.airport-only').find('input, select').prop("disabled", true);
    }
  }

  updateSummaryBars() {
    this.updateSidebarWifi();
    this.updateSummaryBarBottomWifi();
  }

  updateSidebarWifi() {
    const pricing = { wifiPrice: this.wifiPrice, wifiBatteryPrice: this.wifiBatteryPrice };

    $('#booking_session_sidebar').trigger(`recalculatePrice`, pricing );
  }

  updateSummaryBarBottomWifi() {
    const pricing = { wifiPrice: this.wifiPrice, wifiBatteryPrice: this.wifiBatteryPrice };

    $('#booking_session_summary_bar').trigger(`recalculatePrice`, pricing);
  }
  
  get wifiEnabled() {
    return this.wifiEnabledCheckBoxTarget.checked
  }

  get wifiBatteryEnabled() {
    return this.wifiBatteryEnabledCheckBoxTarget.checked
  }

  get deliveryMethod() {
    if (this.wifiAirportDeliveryCheckBoxTarget.checked) {
      return 'airport'
    } else {
      return 'hotel'
    }
  }

  get wifiPrice() {
    var days = this.periodSelectTarget.value,
        price, priceFormatted; 

    if (this.wifiEnabled) {
      price = this.pricingContext.pricesWifi[days];
    } else {
      price = 0;
    }
    
    priceFormatted = `${price} ${this.pricingContext.currency}`;

    return { price: price, priceFormatted: priceFormatted }
  }

  get wifiBatteryPrice() {
    var days = this.periodSelectTarget.value,
        price, priceFormatted; 

    if (this.wifiEnabled && this.wifiBatteryEnabled) {
      price = this.pricingContext.priceBattery * days;
    } else {
      price = 0;
    }
    
    priceFormatted = `${price.toFixed(2)} ${this.pricingContext.currency}`;

    return { price: price, priceFormatted: priceFormatted }
  }

  updateWifiDeliveryCheckBoxes(deliveryMethod) {
    let targetToActivate = this.wifiAirportDeliveryCheckBoxTarget;
    let targetToInactivate = this.wifiHotelDeliveryCheckBoxTarget;
    if (deliveryMethod == 'hotel') { [targetToActivate, targetToInactivate] = [targetToInactivate, targetToActivate] };

    targetToActivate.checked = true;
    targetToActivate.parentNode.classList.add("pickup_option--active");

    targetToInactivate.checked = false;
    targetToInactivate.parentNode.classList.remove("pickup_option--active");
  }
}
